import { useContext } from "react";
import { useState } from "react";
import { createContext } from "react"
import { Loader } from "../componentes/layout/loader/Loader";

const LoaderContext = createContext({})

export function LoaderProvider({children}) {
    const [loader, setLoader] = useState("")

    function showLoader(login = false) {
      setLoader((!login ? "loader" : "loaderLogin"))
    }

    function hideLoader() {
      setLoader("")
    }

    return (
      <LoaderContext.Provider value={{ loader, showLoader, hideLoader}}>
        <div className="wrapper overlay-wrapper">
          <Loader />
          {children}
        </div>
      </LoaderContext.Provider>
    )
}

export function useLoader() {
    const context = useContext(LoaderContext)

    return context;
}